import React from "react"
import { Flex, Heading, Text } from "rebass"

export default function AboutUs() {
  return (
    <Flex color="primary" bg="secondary" variant="section" height={"70vh"}>
      <Flex
        width={["auto", "55%"]}
        justifyContent="center"
        alignItems="center"
        textAlign={["center", "center"]}
        flexDirection="column"
        px={4}
      >
        <Heading
          mb={24}
          fontSize={[6, 7]}
          variant="largeHeading"
          width={["auto", "70%"]}
        >
          We're your development partner
        </Heading>
        <Text fontSize={4} variant="boldContent">
          No matter the complexity, we endeavour to bring the most innovative
          and scalable technology solutions to our customers. We create software
          with custom code, tailored specifically to your needs however big or
          small. Whatever the size of your project, our team is always on hand
          to offer our knowledge and support.
        </Text>
      </Flex>
    </Flex>
  )
}
