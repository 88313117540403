import React from "react"
import Layout from "../components/Layout.js"
import OurServices from "../components/OurServices"
import Hero from "../components/Hero"
import AboutUs from "../components/AboutUs"
import CaseStudies2 from "../components/CaseStudies2"
import Contact from "../components/Contact"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Plank</title>
      </Helmet>
      <Hero />
      <a id="AboutUs"></a>
      <AboutUs />
      <a id="Services"></a>
      <OurServices />
      <CaseStudies2 />
      <Contact />
    </Layout>
  )
}
