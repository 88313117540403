import React from "react"
import { Flex, Heading, Text, Box, Image } from "rebass"
import mightyLinkImage from "../assets/mightylink.png"
import retroCamImage from "../assets/retrocam.png"
import hertfordImage from "../assets/hertford_media.png"
import fenellaImage from "../assets/fenella.png"

const mightyLinkItems = [
  { item: "UX/UI Design" },
  { item: "Logo design" },
  { item: "Custom CMS" },
  { item: "Instagram API integration" },
  { item: "Analytics tracking" },
  { item: "Software as a service - subscription model" },
]
const mightyLinkBullets = mightyLinkItems.map(d => (
  <li key={d.item}>{d.item}</li>
))

const retroCamItems = [
  { item: "UX/UI Design" },
  { item: "Video Codec development" },
  { item: "Full Stack mobile app development" },
  { item: "Instagram API integration" },
]
const retroCamBullets = retroCamItems.map(d => <li key={d.item}>{d.item}</li>)

const fenellaItems = [
  { item: "UX/UI Design" },
  { item: "Full Stack development" },
  { item: "CMS integration" },
]
const fenellaBullets = fenellaItems.map(d => <li key={d.item}>{d.item}</li>)

const hertfordItems = [
  { item: "UX/UI Design" },
  { item: "Full Stack development" },
]
const hertfordBullets = hertfordItems.map(d => <li key={d.item}>{d.item}</li>)

let CaseStudyLeft = ({ title, desc1, desc2, listItems, image }) => (
  <Flex
    flexDirection={["column", "row"]}
    textAlign="left"
    alignItems="center"
    paddingX={["0", 4]}
    marginBottom={5}
  >
    <Flex flexDirection="column" alignItems="center">
      <Box maxWidth={["auto", "50%"]}>
        <Heading fontSize={4} mb={3}>
          {title}
        </Heading>
        <Text color="white" variant="primaryText" mb={3} fontSize={2}>
          {desc1}
        </Text>
        <Text color="white" variant="primaryText" mb={3} fontSize={2}>
          {desc2}
        </Text>
        <Text
          color="secondary"
          textAlign="left"
          variant="primaryText"
          fontSize={2}
        >
          {listItems}
        </Text>
      </Box>
    </Flex>
    <Box marginTop={6} marginRight={[0, 6]}>
      <Image src={image} />
    </Box>
  </Flex>
)

let CaseStudyRight = ({ title, desc1, desc2, listItems, image }) => (
  <Flex
    flex={1}
    flexDirection={["column-reverse", "row"]}
    textAlign="left"
    alignItems="center"
    paddingX={["0", 4]}
    marginBottom={5}
  >
    <Box marginTop={6} marginLeft={[0, 6]}>
      <Image src={image} />
    </Box>

    <Flex flexDirection="column" alignItems="center">
      <Box maxWidth={["auto", "50%"]}>
        <Heading fontSize={4} mb={3}>
          {title}
        </Heading>
        <Text color="white" variant="primaryText" mb={3} fontSize={2}>
          {desc1}
        </Text>
        <Text color="white" variant="primaryText" mb={3} fontSize={2}>
          {desc2}
        </Text>
        <Text
          color="secondary"
          textAlign="left"
          variant="primaryText"
          fontSize={2}
        >
          {listItems}
        </Text>
      </Box>
    </Flex>
  </Flex>
)

export default function AboutUs() {
  return (
    <Flex color="secondary" bg="primary" variant="iconSection" mb={6}>
      <Heading fontSize={6} mb={6}>
        Our Case Studies
      </Heading>
      <Flex justifyContent="space-between" flexDirection={"column"}>
        <CaseStudyLeft
          title="MightyLink"
          desc1="MightyLink allows you to promote multiple links on your Instagram, all under one link."
          desc2="We were tasked with the full stack development of the software. We designed the UX and UI as well as concepting and creating the logo and brand guidelines."
          listItems={mightyLinkBullets}
          image={mightyLinkImage}
        />
        <CaseStudyRight
          title="RetroCam"
          desc1="Retrocam is a mobile app for enhancing videos using overlayed effects. By using smartphone eGPU Retrocam brings professional editing software blending capabilities to your mobile phone."
          desc2="As Plank's first mobile app, we were responsible for the entire design and development of this app."
          listItems={retroCamBullets}
          image={retroCamImage}
        />
        <CaseStudyLeft
          title="Fenella"
          desc1="Fenella Jewellery is a luxury jewellers born out of the heart of Hatton Gardens."
          desc2="Shortly, we will make Fenella into a fully functioning E-Commerce platform as well as automating Fenella’s manufacturing process."
          listItems={fenellaBullets}
          image={fenellaImage}
        />
        <CaseStudyRight
          title="Hertford Media"
          desc1="Hertford Media is a boutique London based Creative Social Media Agency with a fresh and dynamic team committed to working with you to reach your business goals."
          desc2="Plank were briefed to create a streamlined, single paged, UI that allows potential customers to understand what Hertford Media do as quickly as possible."
          listItems={hertfordBullets}
          image={hertfordImage}
        />
      </Flex>
    </Flex>
  )
}
